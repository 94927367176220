/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.test{
	background:	#ffcc00 !important;
}
.myNav {
	background: #2e95d5 !important;
}

/* .myExportBtn {
	box-shadow: 0px 0px 0px 0px #63db41 !important;
	background:linear-gradient(to bottom, #73d98c 5%, #84d953 100%) !important;
	background-color:#73d98c !important;
	border-radius:8px !important;
	border:1px solid #4acc54 !important;
	display:inline-block !important;
	cursor:pointer !important;
	color:#ffffff !important;
	font-family:Arial !important;
	font-size:16px !important;
	font-weight:bold !important;
	padding:6px 20px !important;
	text-decoration:none !important;
	text-shadow:0px -1px 0px #4c8244 !important;
}
.myExportBtn:hover {
	background:linear-gradient(to bottom, #84d953 5%, #73d98c 100%) !important;
	background-color:#84d953 !important;
}
.myExportBtn:active {
	position:relative !important;
	top:1px !important;
} */

.mySearchBtnDisable {
	box-shadow: 0px 1px 0px 0px #b9b9b9 !important;
	background:linear-gradient(to bottom, #e9e9e9 5%, #bbbbbb 100%) !important;
	background-color:#b8b8b8 !important;
	border-radius:6px;
	display:inline-block !important;
	cursor:pointer !important;
	/* color:#000000 !important; */
	color: #000000 !important;
	font-family:Arial !important; 
	font-size:15px !important;
	font-weight:bold !important;
	padding:5px 16px !important;
	text-decoration:none !important;
}

.mySearchBtn {
	box-shadow: 0px 1px 0px 0px #e8bb3f !important;
	background:linear-gradient(to bottom, #ffdb6e 5%, #e8bb3f 100%) !important;
	background-color:#ffdb6e !important;
	border-radius:6px;
	/* border:1px solid #ffdb6e !important; */
	display:inline-block !important;
	cursor:pointer !important;
	/* color:#000000 !important; */
	color: #848587 !important;
	font-family:Arial !important; 
	font-size:15px !important;
	font-weight:bold !important;
	padding:5px 16px !important;
	text-decoration:none !important;
	/* box-shadow: 0px 1px 0px 0px #e8bb3f !important;
	background:linear-gradient(to bottom, #ffdb6e 5%, #e8bb3f 100%) !important;
	background-color:#ffdb6e !important;
	border-radius:8px !important;
	border:1px solid #a17d1a !important;
	display:inline-block !important;
	cursor:pointer !important;
	color:#ffffff !important;
	font-family:Arial !important;
	font-size:16px !important;
	font-weight:bold !important;
	padding:6px 20px !important;
	text-decoration:none !important;
	text-shadow:0px -1px 0px #4c8244 !important; */
}
.mySearchBtn:hover {
	/* background:linear-gradient(to bottom, #f7ba13 5%, #ffbf00 100%) !important;
	background-color:#f7ba13 !important; */

	background:linear-gradient(to bottom, #e8bb3f 5%, #ffdb6e 100%) !important;
	background-color:#e8bb3f !important;
}
.mySearchBtn:active {
	position:relative !important;
	top:1px !important;
}

.myExpBtnDisabled {
	background:linear-gradient(to bottom, #e9e9e9 5%, #bbbbbb 100%) !important;
	background-color:#b8b8b8 !important;
	border-radius:8px !important;
	border:1px solid #cccccc !important;
	display:inline-block !important;
	cursor:pointer !important;
	/* color:#000000 !important; */
	color: #848587 !important;
	font-family:Arial !important;
	font-size:16px !important;
	font-weight:bold !important;
	padding:9px 16px !important;
	text-decoration:none !important;
	/* text-shadow:0px -1px 0px #4c8244 !important; */
}
.myExpBtn {
	/* box-shadow: 0px 1px 0px 0px #e8bb3f !important; */
	background:linear-gradient(to bottom, #ffdb6e 5%, #e8bb3f 100%) !important;
	background-color:#ffdb6e !important;
	border-radius:8px !important;
	border:1px solid #ffdb6e !important;
	display:inline-block !important;
	cursor:pointer !important;
	/* color:#000000 !important; */
	color: #848587 !important;
	font-family:Arial !important;
	font-size:16px !important;
	font-weight:bold !important;
	padding:9px 16px !important;
	text-decoration:none !important;
	/* text-shadow:0px -1px 0px #4c8244 !important; */
}
.myExpBtn:hover {
	background:linear-gradient(to bottom, #e8bb3f 5%, #ffdb6e 100%) !important;
	background-color:#e8bb3f !important;
}
.myExpBtn:active {
	position:relative !important;
	top:1px !important;
}

.padL2 {
  padding-right: 2px !important;
  padding-top: 2px !important;
}

.padT2 {
  padding-top: 2px !important;
}
.padT4{
	padding-top: 4px !important;
}

.myContainer {
	/* width: 500px; */
	/* border: 5px solid rgb(111,41,97); */
	border-radius: .5em;
	padding: 10px;
	display: flex;
	align-items: center;
  }

.myItem {
	/* width: 100px; */
	padding: 2px;
	/* background-color: rgba(111,41,97,.3);
	border: 2px solid rgba(111,41,97,.5); */
	display: inline-block;
	vertical-align: middle
}

.tableFixHead          { overflow: auto; height: 100px; }
.tableFixHead thead th { position: sticky; top: 0; z-index: 1; }

.rootColor {
	color: #616161 !important;
}
.rootColorBackground {
	background: #fcfcfc !important;
}
.fontWeightBold {
	font-weight: bold !important;
}
.whiteSpaceNoWrap{
	white-space: nowrap !important;
}

.listMainContainer {
	padding-left: 10px !important;
}
.paddingRight{
	padding-right: 10px !important;
}
.paddingBottom {
	padding-bottom: 10px !important;
}

.paddingZero {
	padding: 0 !important;
}
.fieldWidth170{
	width: 170px !important;
}
.fieldWidth300{
	width: 300px !important;
}
.paddingRight2{
	padding-right: 2px !important;
}
.chipIconStatus{
	color: #f0f0f0 !important;
	
}
.chipStatus{
	font-weight: bold!important;
	font-size: 1rem!important; 
	color: #f0f0f0!important;
}
.MuiSvgIcon-fontSizeLarge {
    font-size: 2.1875rem !important;
}
.cardCompPadding {
	padding: 25px !important;
}

.deviderStatusLine{
	background-color: #ffffff !important;
	padding-top: 4px !important;
}
.statusTextCount{
	font-size: 25px;
}

.textWidthAutoAdjust2{
	width: 98% !important;
	margin-top: 5px !important;
	margin-bottom: 5px !important;
}
.textWidthAutoAdjust{
	max-width: 100% !important;
	width: 190px !important;
}
.cardPaddintLeftRight10{
	padding-top: 6px !important;
	padding-bottom: 2px !important;
}
.pesoSign{
	font-size: '1.5rem' !important;
}
.textWidthAutoAdjustv2{
    max-width: 100% !important;
		width: 100% !important;
}
@media (max-width: 767px) {
	.pesoSign{
		font-size: '1rem' !important;
	}
	.textWidthAutoAdjust{
		max-width: 100% !important;
		width: 100% !important;
	}
	.statusTextCount{
		font-size: 1rem;
	}
	.deviderStatusLine{
		background-color: #ffffff !important;
		padding-top: 0px !important;
	}
	.cardCompPadding {
		padding: 3px !important;
	}
	.MuiSvgIcon-fontSizeLarge {
		font-size: 1rem !important;
	}
	.chipStatus{
		font-size: 0.8rem!important;
	}
	.myContainer{
		padding: 0;
	}
	.paddingRight2{
		padding-right: 0 !important;
	}
	.fieldWidth300{
		width: 100% !important;
	}
	.fieldWidth170{
		width: 100% !important;
	}
	.paddingBottom {
		padding-bottom: 2px !important;
	}
	.paddingRight{
		padding-right: 0px !important;
	}
	.listMainContainer {
		padding-left: 0px !important;
	}
	.textFieldAdjustWidth{
		max-width: 100% !important;
		width: 100% !important;
		
	}
	.textFieldAdjustPadding{
		padding-right: 5px !important;
		padding-bottom: 5px !important;
	}
	.myStepper{
		font-size: smaller;
	}
}

.hideText{
	display: none !important;
}
.chipRemoveBorder {
	border: 0px !important;
}
.legendContainer {
	text-align: center;
	justify-content: center;
}
.legendItem {
	text-align: left;
	justify-content: left;
}
.legendItemSub {
	text-align: left;
	justify-content: left;
	padding-left: 25px;
}
.textFieldAdjustPadding{
	padding-right: 5px !important;
	padding-bottom: 5px !important;
}

.font15{
	font-size: 15px !important;
}

.myModal{
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.statCreated{
	border-radius: 10px;
	border: 0.1px;
	background: #91abff;
	padding: 5px;
	font-weight: bold;
	/* width: fit-content; */
	color: #003cff;
	text-align: center;
}

.statProcessing{
	border-radius: 10px;
	border: 0.1px;
	background: #ffffba;
	padding: 5px;
	font-weight: bold;
	/* width: fit-content; */
	color: #b8b800;
	text-align: center;
}

.statPending{
	border-radius: 10px;
	border: 0.1px;
	background: #757574;
	padding: 5px;
	font-weight: bold;
	/* width: fit-content; */
	color: #ffffff;
	text-align: center;
}

.statFailed{
	border-radius: 10px;
	border: 0.1px;
	background: #ffabab;
	padding: 5px;
	font-weight: bold;
	/* width: fit-content; */
	color: #ff0000;
	text-align: center;
}

.statCancelled{
	border-radius: 10px;
	border: 0.1px;
	background: #ffe58a;
	padding: 5px;
	font-weight: bold;
	/* width: fit-content; */
	color: #fcc500;
	text-align: center;
}

.statSuccess{
	border-radius: 10px;
	border: 0.1px;
	background: #b3ffd9;
	padding: 5px;
	font-weight: bold;
	/* width: fit-content; */
	color: #00994d;
	text-align: center;
}

.boxRule:nth-of-type(odd){
	border-left: #b3ffd9 solid 2px;
}
.boxRule:nth-of-type(even){
	border-left: #ffb3b3 solid 2px;
}

.dropZoneText{
	/* display: contents !important; */
}
.dropZoneRoot{
	min-height: fit-content !important;
	/* border: none !important; */
}

.tableFixHeader thead th {
	position: sticky;
	top: 0px;
	z-index: 1;
	background-color : #f5f5f5
}

.tableFixHeader tr:nth-child(even) {background-color: #ebfdff;}

.tableHeaderCell{
	white-space: nowrap;
}

.tableChild{
	/* width: inherit; */
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.freezeText{
	position: sticky;
	right: 0px;
	z-index: 1;	
}