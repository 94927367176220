/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body, html, span, th, td, p {
  /* font-family: 'Helvetica Neue' !important; */
  color: '#616161' !important;
  /* font-size: 1em; */
  font-family: 'Helvetica' !important;
  font-size: 12px;
}

/* code {
  font-family: 'Helvetica Neue' !important;
} */